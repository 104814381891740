import * as Sentry from '@sentry/react';

export const init = (): any => {
	const { REACT_APP_SENTRY_DSN, REACT_APP_SENTRY_ENV } = process.env;

	const isDev = REACT_APP_SENTRY_ENV === 'development';

	if (REACT_APP_SENTRY_DSN) {
		Sentry.init({
			dsn: REACT_APP_SENTRY_DSN,
			integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
			environment: process.env.REACT_APP_SENTRY_ENV,
			// Tracing
			tracesSampleRate: 1.0, //  Capture 100% of the transactions
			// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
			tracePropagationTargets: ['localhost', /^https:\/\/app\.kennekt\.com\/api/],
			// Session Replay
			replaysSessionSampleRate: isDev ? 1.0 : 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
			replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
			ignoreErrors: [
				'ResizeObserver loop limit exceeded',
				'ResizeObserver loop completed with undelivered notifications'
			]
		});
	}
};

export default {
	init
};
