import React, { useState } from 'react';
import { useAppState } from 'shared/state';
import Popover from '@material-ui/core/Popover';
import { /*webpackPrefetch: true */ LogoKnt } from './components/LogoKnt';
import { SidebarToggleBtn } from './components/SidebarToggleBtn';
import { DarkToggleBtn } from './components/DarkToggleBtn';
import { SearchBar } from './components/SearchBar';
import { CreateCaseBtn } from './components/CreateCaseBtn';
import { UserAvatar } from './components/UserAvatar';
import { UserPopover } from './components/UserPopover';
import { RecentCasesBtn, TemplatesFilterBtn } from './components';
import { LanguageSelect } from './components/LanguageSelect/LanguageSelect';
import { CaseTypeSelect } from './components/CaseTypeSelect';
import { WhiteModal } from '../Elements';
import { NewCaseFilter } from 'pages/Cases/filter-components';
import clsx from 'clsx';
import { isMobile } from 'react-device-detect';
import { useRouter } from '../../hooks';
import { useAuth } from '../../../modules/auth/hooks/useAuth';
import { useTranslationHelpers } from 'shared/hooks/useTranslationHelpers';

export function HeaderKnt() {
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
	const [filterOpen, setFilterOpen] = useState(false);

	const { searchQuery, companyId, isAppAvailable } = useAppState();
	const { location } = useRouter();
	const { companies } = useAuth();
	const { isRTL } = useTranslationHelpers();

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleModalOpen = () => {
		setFilterOpen((prev) => !prev);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const id = open ? 'user-profile' : undefined;

	const isInCasePage = location.pathname.includes('/case-messages');
	const hasToShowCreateCaseButton = isAppAvailable && (!searchQuery.length || companyId);

	return (
		<div className="flex h-full">
			<div className="flex flex-grow items-center pt-1 pb-1 md:pt-3.5 md:pb-3.5 lg:justify-between">
				<div className={clsx('flex h-full', { 'items-center': isMobile })}>
					<LogoKnt />
					<SidebarToggleBtn />
					{companyId ? <SearchBar /> : null}
					{isInCasePage && <TemplatesFilterBtn handleModalOpen={handleModalOpen} />}
					{hasToShowCreateCaseButton ? <CreateCaseBtn /> : null}
				</div>
				<div className="flex items-center">
					<LanguageSelect isRegistering={false} />
					<DarkToggleBtn />
					{isAppAvailable && <RecentCasesBtn />}
					{isInCasePage && <CaseTypeSelect />}
				</div>
			</div>
			{companyId || companies.length === 1 ? (
				<button
					className={clsx('flex flex-shrink-0 cursor-pointer items-center ', {
						'lg:pl-7': !isRTL,
						'lg:pr-7': isRTL
					})}
					color="primary"
					type="button"
					onClick={handleClick}
				>
					<UserAvatar />
				</button>
			) : null}
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left'
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center'
				}}
			>
				<UserPopover />
			</Popover>
			<WhiteModal
				handleModalOpen={handleModalOpen}
				open={Boolean(filterOpen)}
				minWidth={362}
				// className={'!w-[1086px] !max-w-[1086px]'}
				// className={''}
				// containerClasses="fixed lg:static"
				// maxWidth={362}
				paperWidth={1086}
				noPadding
				scrollable
			>
				<NewCaseFilter handleModalOpen={handleModalOpen} />
			</WhiteModal>
		</div>
	);
}
