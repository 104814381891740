import React, { useEffect, useState, useCallback, useRef } from 'react';
import axios from 'axios';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import { useDropzone } from 'react-dropzone';
import config from 'config';
import { useChatHelpers } from './useChatHelpers';
import { nanoid } from 'nanoid';
import { useAppState } from 'shared/state';
import { useAuth } from 'modules/auth/hooks/useAuth';
import { useChatInputContext } from 'shared/contexts/ChatInputContext';
import { useChatContext } from 'shared/contexts/ChatContext';
import { useChatSocket } from 'shared/contexts/ChatSocketContext';
import { MsgPayload, useChatMessage } from './useChatMessage';
import { useNotistack } from '../useNotistack';
import { createMessageFileFormData, getFileType } from 'shared/services';
import { getRepliedToId } from 'shared/components/Chat/utils/getRepliedToId';
import { useCheckForLastPageIsFetched } from '../../components/Chat/hooks/useCheckForLastPageIsFetched';

export function useChatUpload({ isDragging = false }) {
	const { getFileThumb, getVideoThumb, resetUploadMeta } = useChatHelpers();
	const {
		editingMessage,
		reply,
		setEditProgress,
		setUploadMeta,
		uploadMeta,
		setReply,
		cancelSending,
		editCancelUpload,
		clipboardFile,
		setClipboardFile,
		cancelAudioUpload,
		setAudioCancelSending,
		setAudioUploadProgress,
		messageScheduleDate,
		setMessageScheduleDate
	} = useChatInputContext();
	const { entityId, entityType, isScheduledChat } = useChatContext();
	const { companyId } = useAppState();
	const { token } = useAuth();
	const [state, setState] = useState<any>({
		uuids: {},
		progresses: {},
		cancelTokensByName: {},
		cancelTokensByUid: {},
		cancelTokensByUuid: {},
		initialFiles: {},
		lastUuid: null,
		audioCancelToken: null,
		audioUploadProgress: {}
	});
	const { sendJsonMessage } = useChatSocket();
	const { sendChatMessage } = useChatMessage({ sendJsonMessage, entityId, entityType });
	const { showNotification } = useNotistack();

	// const { lastPageIsNotFetched, setTempFileList, setIsDelayedMessage } =
	// 	useCheckForLastPageIsFetched();

	const fileUIDs = useRef([]);
	const clipboardImageRef = useRef(null);
	const action =
		editingMessage || isScheduledChat
			? `${config.API_ROOT}/${companyId}/upload/`
			: `${config.API_ROOT}/messages/file-upload/`;

	const { lastPageIsNotFetched, setTempFileList, setIsDelayedMessage, handleScheduledFileUpload } =
		useCheckForLastPageIsFetched({
			handleUpload: handleUploadStart
		});

	const {
		uuids,
		progresses,
		initialFiles,
		cancelTokensByUid,
		cancelTokensByUuid,
		lastUuid,
		audioUploadProgress,
		audioCancelToken
	} = state;

	//Only for dragging upload. Remove metadata from state
	useEffect(() => {
		setState((prev: any) => {
			const clonedState = cloneDeep(prev);
			if (lastUuid in clonedState.initialFiles) delete clonedState.initialFiles[lastUuid];
			if (lastUuid in clonedState.progresses) delete clonedState.progresses[lastUuid];

			return clonedState;
		});
	}, [lastUuid]);

	//Processing file after drag or attachment
	const fileOnStart = useCallback(
		(file) => {
			const fileSize = file.size > 1048576 ? Math.trunc(file.size / 1024 / 1024) : 1;
			const currentUuid = editingMessage ? editingMessage?.custom_uuid : nanoid();
			const CancelToken = axios.CancelToken;
			const source = CancelToken.source();

			//This is important
			const currUploadingFile = {
				...file,
				uid: file.uid,
				type: file.type,
				name: file.name,
				key: file.key,
				size: file.size,
				lastModified: file.lastModified
			};
			const fileId = `${isDragging ? file.name : file.uid}`;
			const cancelTokenId = `${isDragging ? 'cancelTokensByName' : 'cancelTokensByUid'}`;

			setState((prev: any) => {
				return {
					...prev,
					uuids: {
						...prev.uuids,
						[fileId]: currentUuid
					},
					[cancelTokenId]: {
						...prev[cancelTokenId],
						[fileId]: source
					},
					cancelTokensByUuid: {
						...prev.cancelTokensByUuid,
						// @ts-ignore
						[currentUuid]: source
					},
					// @ts-ignore
					initialFiles: { ...prev.initialFiles, [currentUuid]: currUploadingFile }
				};
			});

			return new Promise((resolve, reject) => {
				if (fileSize < 201) {
					const reader = new FileReader();
					const imgFile = file;

					const type =
						imgFile && imgFile.type.split('/')[0].length > 0 ? imgFile.type.split('/')[0] : 'other';

					reader.onloadend = () => {
						const preview =
							type === 'image'
								? reader.result
								: type === 'video'
								? getVideoThumb()
								: getFileThumb();
						const payload = {
							type: 'image',
							preview: {
								name: imgFile.name,
								imagePreviewUrl: preview
							},
							size: (file.size / 1024 / 1024).toFixed(2),
							uuid: currentUuid,
							replied_to_id: getRepliedToId(reply),
							// replied_to: getRepliedTo(reply)
							replied_to: null,
							scheduled_to: messageScheduleDate
						};

						const chatId = { msgSendingChatId: entityId };
						const payloadData = { ...payload, ...chatId } as MsgPayload;
						sendChatMessage(payloadData);
					};

					reader.readAsDataURL(imgFile);
					const resolvedData = isDragging
						? { file, source, currFile: currUploadingFile, currentUuid }
						: file;
					resolve(resolvedData);
				} else {
					reject(new Error('Too big filesize'));
					showNotification({
						message:
							'Sorry, There is a limit for attachment size. Please attach files less than 200 mb',
						variant: 'info'
					});
				}
			});
		},
		[reply, entityId, editingMessage, isScheduledChat, messageScheduleDate]
	);

	//Handling message sending when uploading finishes successfully
	const fileOnSuccess = useCallback(
		({ response, _file, currFile, currentUuid, type }) => {
			// const currentUuid = uuids[_file.name];
			!isDragging && setEditProgress(null);

			//Reset progress data when each file has been already been uploaded
			resetUploadMeta({ handler: setUploadMeta, metaObj: uploadMeta, uuid: currentUuid });

			//clean up file related state infos
			if (!isDragging) {
				setState((prev: any) => {
					const clonedState = cloneDeep(prev);
					delete clonedState.initialFiles[currentUuid];
					delete clonedState.progresses[currentUuid];
					delete clonedState.cancelTokensByUuid[currentUuid];
					delete clonedState.cancelTokensByUid[_file.uid];
					delete clonedState.uuids[_file.uid];

					return clonedState;
				});
			}

			const file = {
				file: {
					...currFile,
					response: {
						...response
					}
				}
			};
			const fileId = isDragging ? file.file.name : file.file.uid;
			// let fileDuration = 0;
			const isFileHasBeenUpload = !fileUIDs?.current.find((string) => string === fileId);
			const generalPayload = {
				file,
				uuid: currentUuid,
				replied_to_id: getRepliedToId(reply),
				// replied_to: getRepliedTo(reply)
				replied_to: null,
				scheduled_to: messageScheduleDate
			};
			const groupRelatedPayload = { type: 'file', msgSendingChatId: entityId };
			const payload = { ...generalPayload, ...groupRelatedPayload } as MsgPayload;
			const handleFileSend = sendChatMessage;
			if (get(file, 'file.name').match(/\.(avi|mp3|mp4|mpeg|ogg|mov)$/i)) {
				// document.getElementById('audio').setAttribute('src', file.file.response.url);
				// @ts-ignore
				// document.getElementById('audio').addEventListener('canplaythrough', function (e) {
				// @ts-ignore
				// fileDuration = Math.round(e?.currentTarget?.duration);
				// set(file, 'file_duration', fileDuration);
				if (isFileHasBeenUpload) {
					// @ts-ignore
					type !== 'clipboard' && fileUIDs.current.push(fileId);
					if (editingMessage || isScheduledChat) {
						handleFileSend(payload);
					}
					setReply(null);
				}
				// });
			} else {
				if (isFileHasBeenUpload) {
					// @ts-ignore
					type !== 'clipboard' && fileUIDs.current.push(fileId);

					if (editingMessage || isScheduledChat) {
						handleFileSend(payload);
					}
					setReply(null);
				}
			}
		},
		[setUploadMeta, uploadMeta, setReply, reply, entityId, isScheduledChat, messageScheduleDate]
	);

	const onProgress = ({ percent }: { percent: { percent: number; uuid: string } }, file: any) => {
		setState((prev: any) => ({
			...prev,
			progresses: { ...prev.progresses, [percent.uuid]: percent.percent }
		}));
	};

	// eslint-disable-next-line no-unused-vars
	const onError = (err: any) => {
		// if (isEditing) {
		// 	//@ts-ignore
		// 	setEditMsgType(initEditMsgType?.current);
		// 	setEditCancelUpload(false);
		// }
	};

	// Handle cancelling uploading files
	useEffect(() => {
		const uuid = cancelSending || editCancelUpload;
		const hasToCancel = isDragging ? isEmpty(cancelTokensByUid) : !isEmpty(cancelTokensByUid);

		if (uuid && hasToCancel) {
			const errorMessage = 'Uploading canceled by the user.';
			if (typeof uuid === 'string') cancelTokensByUuid?.[uuid]?.cancel(errorMessage);

			setMessageScheduleDate(null);

			//clean up state progress infos because it will have been updating progress data of uploadMeta state
			setState((prev: any) => {
				const clonedState = cloneDeep(prev);
				if (typeof uuid === 'string') delete clonedState.progresses[uuid];
				return clonedState;
			});

			//Reset progress data when file upload has been canceled
			if (typeof uuid === 'string')
				resetUploadMeta({ handler: setUploadMeta, metaObj: uploadMeta, uuid });
		}
	}, [cancelSending, editCancelUpload]);

	//Setting progress of uploading data
	useEffect(() => {
		if (!isEmpty(progresses)) setUploadMeta(progresses);
	}, [progresses]);

	//Handling uploading files
	function handleUploadStart(file: any, type = 'default') {
		fileOnStart(file)
			// @ts-ignore
			.then(async ({ file, source, currFile, currentUuid }) => {
				const fileType = getFileType(file as File);
				const formData = await createMessageFileFormData({
					filename: file.name as string,
					file: file as File,
					companyId: companyId!.toString(),
					entityType: entityType === 'ims' ? 'ims' : 'case',
					entityId: entityId?.toString(),
					fileType,
					//@ts-ignore
					custom_uuid: currentUuid
				});

				axios
					.post(action, formData, {
						headers: {
							Authorization: 'Token ' + token
						},
						onUploadProgress: (progressEvent) => {
							const { total, loaded } = progressEvent;
							setState((prev: any) => ({
								...prev,
								progresses: {
									...prev.progresses,
									[currentUuid]: Number(Math.round((loaded / total) * 100).toFixed(2))
								}
							}));
						},
						cancelToken: source.token
					})
					.then(({ data }) => {
						if (isDragging) {
							setState((prev: any) => ({ ...prev, lastUuid: currentUuid }));
						}
						fileOnSuccess({
							response: data,
							_file: file,
							currFile,
							currentUuid,
							type
						});

						setMessageScheduleDate(null);
					})
					.catch((err) => {
						console.error(err);
					});
			})
			.catch((err) => console.log('err uploading', err));
	}

	// Handle file upload when drag'n'drop happens
	const onDrop = React.useCallback(
		(acceptedFiles) => {
			if (isScheduledChat && !messageScheduleDate && !editingMessage) {
				return handleScheduledFileUpload(acceptedFiles, true);
			}
			//Upload the files
			if (lastPageIsNotFetched) {
				return handleScheduledFileUpload(acceptedFiles, false);
			}

			acceptedFiles.forEach((file: any) => handleUploadStart(file));
		},
		[entityId]
	);

	//React dropzone custom hook
	const { getRootProps, getInputProps, isDragAccept, isDragReject } = useDropzone({
		onDrop,
		noClick: true
	});

	//Show image preview on copy-paste
	const getImagePreview = (file: any) => {
		if (file.type.startsWith('image/')) {
			const fileReader = new FileReader();
			fileReader.readAsDataURL(file);
			fileReader.onload = () => {
				if (clipboardImageRef.current) {
					// @ts-ignore
					clipboardImageRef.current.src = fileReader?.result;
				}
			};
		}
	};
	// Handling file attach in Dialog
	const handleClipboardClose = (file = null) => {
		// @ts-ignore
		if (file.type.startsWith('image/')) {
			//Upload  the files
			if (isScheduledChat && !messageScheduleDate && !editingMessage) {
				handleScheduledFileUpload([file as any], true);
			} else if (lastPageIsNotFetched) {
				handleScheduledFileUpload([file as any], false);
			} else {
				handleUploadStart(file, 'clipboard');
			}
		}
		// @ts-ignore
		clipboardImageRef.current.src = '';
		setClipboardFile(false);
	};

	//Handle Copy paste files upload
	const handlePaste = (e: any) => {
		const file = e.clipboardData.files[0];
		if (e.clipboardData.files.length) {
			setClipboardFile(file);
			getImagePreview(file);
		}
	};

	//Registering paste handler to detect pasted files from clipboard and upload them
	useEffect(() => {
		if (entityId) {
			document.onpaste = handlePaste; // document.addEventListener('paste', handlePaste) caused bugs
		}

		return () => {
			document.removeEventListener('paste', handlePaste);
		};
	}, [entityId]);

	//Setting progress of uploading data
	useEffect(() => {
		if (audioUploadProgress) {
			setAudioUploadProgress(audioUploadProgress);
		}
	}, [audioUploadProgress]);

	// Handle cancelling uploading audio
	useEffect(() => {
		if (cancelAudioUpload && audioCancelToken) {
			const errorMessage = 'Uploading canceled by the user.';
			audioCancelToken?.cancel(errorMessage);

			setAudioUploadProgress({});
			setAudioCancelSending(false);
			setState((prev: any) => {
				return {
					...prev,
					audioCancelToken: null
				};
			});
		}
	}, [cancelAudioUpload]);

	/*
	* const CancelToken = axios.CancelToken;
			const source = CancelToken.source();
	* */
	const uploadAudioFile = async (file: any) => {
		const audioUuid = editingMessage ? editingMessage.custom_uuid : nanoid();
		const payload = {
			type: 'audio',
			size: (file.size / 1024 / 1024).toFixed(2),
			uuid: audioUuid,
			replied_to_id: getRepliedToId(reply),
			replied_to: null,
			scheduled_to: messageScheduleDate
		};

		const chatId = { msgSendingChatId: entityId };
		const payloadData = { ...payload, ...chatId, edited: !!editingMessage } as MsgPayload;
		sendChatMessage(payloadData);

		const filename = `${Math.random()}`.slice(2) + '.mp3';
		const fileType = getFileType(file as File);

		const formData = await createMessageFileFormData({
			filename: filename as string,
			file: file as File,
			companyId: companyId!.toString(),
			entityType: entityType === 'ims' ? 'ims' : 'case',
			entityId: entityId?.toString(),
			fileType,
			//@ts-ignore
			custom_uuid: audioUuid,
			repliedToId: getRepliedToId(reply)
		});

		const CancelToken = axios.CancelToken;
		const source = CancelToken.source();
		setState((prev: any) => {
			return {
				...prev,
				audioCancelToken: source
			};
		});

		axios
			.post(action, formData, {
				headers: {
					Authorization: `Token ${token}`
				},
				onUploadProgress: (progressEvent) => {
					const { total, loaded } = progressEvent;
					setState((prev: any) => {
						return {
							...prev,
							audioUploadProgress: {
								...prev.audioUploadProgress,
								[audioUuid]: Number(Math.round((loaded / total) * 100).toFixed(2))
							}
						};
					});
				},
				cancelToken: source.token
			})
			//@ts-ignore
			.then(({ data }) => {
				setState((prev: any) => {
					return {
						...prev,
						audioUploadProgress: {}
					};
				});
				setReply(null);
				setMessageScheduleDate(null);
			})
			.catch((e) => {
				console.log('error audio upload', e);
			});
	};

	return {
		fileOnStart,
		fileOnSuccess,
		getRootProps,
		getInputProps,
		isDragAccept,
		isDragReject,
		onProgress,
		onError,
		uuids,
		cancelTokensByUid,
		initialFiles,
		clipboardFile,
		setClipboardFile,
		clipboardImageRef,
		handleClipboardClose,
		action,
		uploadAudioFile,
		handleUploadStart
	};
}
